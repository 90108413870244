import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { CircularProgress } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
   root: {
      width: '100%',
      height: '100vh',
      background: theme.palette.glass.main,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
   },
   wait: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '25px',
      flexDirection: 'column',
      height: '80vh',
      width: '95vw',
      maxWidth: '700px',
   },
});

function Loading({ classes, history }) {
   return (
      <div className={classes.root}>
         <AppBar color='secondary'>
            <Toolbar></Toolbar>
         </AppBar>
         <Card className={classes.wait}>
            <Typography color='secondary' variant='h6'>
               {' '}
               Loading...
            </Typography>
            <br />
            <CircularProgress />
         </Card>
      </div>
   );
}

export default withStyles(styles)(Loading);
