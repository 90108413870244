import React, { Suspense, lazy, useState, useEffect } from 'react';
import { render } from 'react-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import Loading from './Loading.js';
import firebase from 'firebase';

const TermApp = lazy(() => import('./TermApp.js'));
const Home = lazy(() => import('./Home.js'));
const Workshop = lazy(() => import('./Workshop.js'));
const Admin = lazy(() => import('./Admin.js'));
const About = lazy(() => import('./About.js'));

const PresentationSlidePlayer = lazy(() => import('./PresentationSlidePlayer.js'));
const SignIn = lazy(() => import('./SignIn.js'));
const EditTerm = lazy(() => import('./EditTerm.js'));
const Classrooms = lazy(() => import('./Classrooms.js'));
const AssignActivities = lazy(() => import('./AssignActivities.js'));
const AssignmentProgress = lazy(() => import('./AssignmentProgress.js'));
const AssignmentProgressSLO = lazy(() => import('./AssignmentProgressSLO.js'));

const BlanxsterGame = lazy(() => import('./BlanxsterGame.js'));
const Upgrade = lazy(() => import('./Upgrade.js'));
const Subscription = lazy(() => import('./Subscription.js'));

const dark = '#00264d';
const light = '#7FfFbF';
const mid = '#fff';
const pink = '#fb666d';
const glass = '#cbf9ff';

// const dark = "#353535"
// const light = "#7FfFbF"
// const mid = "#fff"

const theme = createMuiTheme({
   typography: {
      useNextVariants: true,
      fontFamily: 'Helvetica',
      display1: {
         color: dark,
      },
      body2: {
         color: dark,
      },
      headline: {
         color: dark,
      },
      display4: {
         color: dark,
      },
   },
   palette: {
      type: 'light',

      primary: {
         main: light,
         contrastText: dark,
      },
      secondary: {
         main: dark,
         contrastText: light,
      },
      tertiary: {
         main: mid,
         contrastText: dark,
      },
      pink: {
         main: '#fb666d',
         contrastText: dark,
      },
      glass: {
         main: '#cbf9ff',
         contrastText: dark,
      },
      error: {
         main: '#db955c',
      },
   },
   overrides: {
      MuiInput: {
         underline: {
            '&:before': {
               borderBottomColor: glass,
            },
            '&:after': {
               borderBottomColor: pink,
            },
            '&:hover:before': {
               borderBottomColor: [light, '!important'],
            },
         },
      },
      //to customize the TextField input you had to look at the CSS in the browser and see what Class was applied
      //here is was "MuiInputBase-input" then you write it here as nested objects...
      MuiInputBase: {
         input: {
            color: dark,
         },
      },
      MuiInputLabel: {
         root: {
            '&$focused': {
               color: pink,
               borderColor: 'red',
            },
         },
      },
      MuiButton: {
         contained: {
            backgroundColor: light,
            '&:hover': {
               backgroundColor: glass,
               transform: 'scale(1.02)',
            },
         },
         outlined: {
            backgroundColor: light,
            '&:hover': {
               backgroundColor: glass,
               transform: 'scale(1.02)',
            },
         },
      },
      MuiListItem: {
         button: {
            '&:hover': {
               backgroundColor: glass + 'aa',
               // transform: "scale(1.07)",
            },
         },
      },
   },
});
// const Loading = () => {
//    return (<div>Loading...</div>)
// }

function Index() {
   return (
      <MuiThemeProvider theme={theme}>
         <CssBaseline />
         <Suspense fallback={<Loading />}>
            <BrowserRouter>
               <div>
                  <Route exact path='/' component={Home} />
                  <Route exact path='/drawer/:open' component={Home} />
                  <Route exact path='/term/:key' render={(props) => <TermApp {...props} key={props.match.params.key} />} />
                  <Route exact path='/shared/:listKey/firstTerm=:key' render={(props) => <TermApp {...props} key={props.match.params.key} />} />
                  <Route exact path='/workshop/:key' render={(props) => <Workshop {...props} key={props.match.params.key} />} />
                  <Route exact path='/shared/w/:listKey/firstTerm=:key' render={(props) => <Workshop {...props} key={props.match.params.key} />} />
                  <Route exact path='/admin/' component={Admin} />
                  <Route exact path='/about/' component={About} />
                  <Route exact path='/load/' component={Loading} />
                  <Route exact path='/present/:listId/:termKeys' component={PresentationSlidePlayer} />
                  <Route exact path='/signin/:key' component={SignIn} />
                  <Route exact path='/editTerm/:key' component={EditTerm} />
                  <Route exact path='/classrooms/:uid' component={Classrooms} />
                  <Route exact path='/upgrade/:uid' component={Upgrade} />
                  <Route exact path='/subscription/:uid' component={Subscription} />
                  <Route exact path='/AssignActivities/:uid/:roomId' component={AssignActivities} />
                  <Route exact path='/Progress/:roomId/:uid/:course/:section/:assignmentId/:type/:listId/:listName' component={AssignmentProgress} />
                  <Route exact path='/SLOreport/:roomId/:uid/:course/:section/:listId/:listName/:assignmentIds' component={AssignmentProgressSLO} />
                  <Route path='/blanxster/:listId/:assignmentId' render={(props) => <BlanxsterGame {...props} key={props.match.params.listId} />} />
               </div>
            </BrowserRouter>
         </Suspense>
      </MuiThemeProvider>
   );
}

const rootElement = document.querySelector('#root');
if (rootElement) {
   render(<Index />, rootElement);
}
